/* Main Slider Styles */

.visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
}

.ttr-slider {
  position: relative;
  overflow: hidden;
  width: 100%;

  .slider-item {
    padding: 0;
    position: relative;
  }

  .content-inner {
    display: grid;

    h1 {
      font-weight: bold;
      padding-top: 15%;
      z-index: 100;

      /* Desktop styles */
      font-size: 4rem;

      /* Mobile styles */
      @media (max-width: 767px) {
        font-size: 2.5rem;
      }
    }
  }

  .slider-img {
    background-size: cover;
    background-position: center;
    height: 100vh;

    /* Background images set via CSS */
    background-image: url('../../images/slider/slide1.webp');

    @media (max-width: 768px) {
      background-image: url('../../images/slider/mobile_optimized_slide1.webp');
    }

    img {
      /* The visually-hidden class hides the image visually but keeps it accessible */
      @extend .visually-hidden;
    }
  }

  .slider-content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
  }

  .car-wheel {
    display: inline-block;
    position: fixed;
    bottom: 5%;
    animation: float 2.5s infinite;
    opacity: 0.6;

    picture {
      img {
        width: 100%;
        height: auto;
      }
    }

    .wheel-1,
    .wheel-2 {
      position: absolute;
      width: 17%;
      height: 46.555%;

      img {
        width: 100%;
        animation: spin1 2.5s infinite linear;
      }
    }

    .wheel-1 {
      left: 7%;
      bottom: 13%;
    }

    .wheel-2 {
      right: 6.5%;
      bottom: 13%;
    }

    /* Tablet adjustments (max-width: 1400px) */
    @media (max-width: 1400px) {
      bottom: 8%;

      .wheel-1,
      .wheel-2 {
        bottom: 11%;
        width: 17%;
      }

      .wheel-1 {
        left: 7%;
      }

      .wheel-2 {
        right: 7%;
      }
    }

    /* Tablet adjustments (max-width: 1024px) */
    @media (max-width: 1024px) {
      bottom: 8%;

      .wheel-1,
      .wheel-2 {
        bottom: 11%;
        width: 15%;
      }

      .wheel-1 {
        left: 12%;
      }

      .wheel-2 {
        right: 12%;
      }
    }

    /* Mobile adjustments (max-width: 768px) */
    @media (max-width: 768px) {
      bottom: 10%;

      .wheel-1,
      .wheel-2 {
        bottom: 14%;
        width: 18%;
      }

      .wheel-1 {
        left: 7%;
      }

      .wheel-2 {
        right: 7%;
      }
    }

    /* Mobile smaller adjustments (max-width: 480px) */
    @media (max-width: 480px) {
      bottom: 12%;

      .wheel-1,
      .wheel-2 {
        bottom: 14%;
        width: 18%;
      }

      .wheel-1 {
        left: 7%;
      }

      .wheel-2 {
        right: 7%;
      }
    }
  }

  .slide-move {
    animation: bgMove 40s infinite linear;
    background-repeat: repeat-x;
    background-size: cover;

    img {
      opacity: 0;
    }
  }

  /* Keyframes for Background Movement */
  @keyframes bgMove {
    100% {
      background-position: 3000px 0px;
    }
  }

  /* Keyframes for Wheel Spin */
  @keyframes spin1 {
    0% {
      transform: rotate(-320deg);
    }
    100% {
      transform: rotate(-680deg);
    }
  }

  /* Keyframes for Car Float */
  @keyframes float {
    0%,
    100% {
      transform: translateX(10px);
    }
    50% {
      transform: translateX(-15px);
    }
  }

  /* Slider Style 2 */
  &.style-2 {
    .slider-content {
      top: 30px;
      bottom: 30px;
      transform: translateX(-50%);

      @media (max-width: 768px) {
        top: 50px;
      }

      .title {
        font-size: 110px;
        line-height: 110px;
        text-transform: uppercase;
        margin-top: 10px;
        animation: move2 5s infinite alternate;
      }

      .sub-title {
        padding-top: 60px;
        font-size: 36px;
        text-transform: uppercase;
        font-weight: 500;
        margin-bottom: 25px;
        letter-spacing: 2px;
        animation: move2 5s infinite alternate;

        &:before,
        &:after {
          content: "//";
          font-family: "Roboto", sans-serif;
          color: $primary;
          font-weight: 900;
          transform: scale(0.93) translateY(-3%);
          display: inline-block;
        }

        &:before {
          margin-right: 15px;
        }

        &:after {
          margin-left: 15px;
        }
      }
    }

    @media (max-width: 1400px) {
      .car-wheel {
        width: 75%;
      }

      .slider-content .title {
        font-size: 80px;
        line-height: 80px;
      }

      .slider-content .sub-title {
        font-size: 28px;
        margin-bottom: 15px;
      }
    }

    @media (max-width: 1024px) {
      .slider-content .title {
        font-size: 62px;
        line-height: 62px;
      }

      .slider-content .sub-title {
        font-size: 20px;
        margin-bottom: 10px;
      }
    }

    @media (max-width: 768px) {
      .slider-content .title {
        font-size: 52px;
      }

      .swiper-pagination {
        right: 25px;
      }
    }

    @media (max-width: 480px) {
      .slider-content .title {
        font-size: 42px;
        line-height: 42px;
      }

      .slider-content .sub-title {
        font-size: 18px;

        &:before {
          margin-right: 5px;
        }

        &:after {
          margin-left: 5px;
        }
      }

      .swiper-pagination {
        top: auto;
        transform: unset;
        right: 0;
        width: 100%;
        left: 0;
        bottom: 20px;
        display: flex;
        justify-content: center;

        &-bullet {
          margin: 10px;
        }
      }
    }
  }

  /* Slider Style 1 */
  &.style-1 {
    .slider-content {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 80px 80px 100px;

      .title {
        font-size: 80px;
        line-height: 90px;
        text-transform: uppercase;
        margin-bottom: 50px;
      }

      .sub-title {
        font-size: 28px;
        text-transform: uppercase;
        font-weight: 500;
        margin-bottom: 20px;
        letter-spacing: 2px;
      }

      p {
        font-size: 18px;
        line-height: 30px;
        margin-bottom: 40px;
        margin-right: 50px;
      }
    }

    .swiper-pagination {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 50px;
      width: 45px;
      height: auto;
      left: auto;
      bottom: auto;

      &-bullet {
        height: 12px;
        width: 12px;
        border-radius: 0;
        display: block;
        margin: 18px;
        background-color: rgba(255, 255, 255, 0.4);
        opacity: 1;
        transition: all 0.3s;

        &-active {
          background-color: var(--primary);
          outline: 1px solid #515151;
          outline-offset: 3px;
        }
      }
    }

    .slider-thumbs {
      position: absolute;
      bottom: 25px;
      left: 50%;
      transform: translateX(-50%);

      .thumbs-item {
        display: flex;
        align-items: center;
        border-bottom: 3px solid;
        border-color: #fff;
        padding-bottom: 25px;
        padding-right: 30px;
        opacity: 0.4;
        transition: all 0.3s;
        cursor: pointer;

        .title {
          font-size: 16px;
          color: #fff;
          font-weight: 500;
          margin: 0;
          line-height: 24px;
        }

        .play-btn {
          height: 45px;
          width: 45px;
          min-width: 45px;
          line-height: 45px;
          color: #000;
          text-align: center;
          background-color: #fff;
          margin-right: 18px;
        }
      }

      .swiper-slide-thumb-active {
        .thumbs-item {
          opacity: 1;
          border-color: var(--primary);
        }
      }
    }

    .videoplay-bx1 {
      margin: auto;
    }

    @media (max-width: 1400px) {
      .slider-content .title {
        font-size: 62px;
        line-height: 70px;
      }

      .swiper-pagination {
        right: 30px;
      }
    }

    @media (max-width: 1024px) {
      .slider-content .title {
        font-size: 42px;
        line-height: 52px;
      }

      .slider-content .sub-title {
        font-size: 20px;
        margin-bottom: 15px;
      }
    }

    @media (max-width: 768px) {
      .slider-content .title {
        margin-bottom: 20px;
      }
    }

    @media (max-width: 600px) {
      .slider-content {
        padding: 80px 30px 120px;

        .title {
          font-size: 36px;
          line-height: 46px;
        }

        .sub-title {
          font-size: 18px;
          margin-bottom: 15px;
        }
      }

      .swiper-pagination {
        right: 10px;
      }

      .slider-thumbs .thumbs-item {
        padding-bottom: 15px;
        padding-right: 15px;

        .play-btn {
          height: 40px;
          width: 40px;
          min-width: 40px;
          line-height: 40px;
          margin-right: 15px;
        }

        .title {
          font-size: 14px;
        }
      }
    }

    @media (max-width: 480px) {
      .slider-img {
        height: 600px;
      }

      .slider-content {
        padding: 80px 15px;

        .title {
          font-size: 32px;
          line-height: 40px;
        }

        .sub-title {
          font-size: 18px;
          margin-bottom: 10px;
        }
      }

      .swiper-pagination {
        display: none;
      }

      .slider-thumbs .swiper-slide {
        width: 250px;
      }
    }
  }
}

/* Video Play Button */
.videoplay-bx1 {
  height: 80px;
  width: 80px;
  line-height: 80px;
  text-align: center;
  color: #000000;
  display: block;
  font-size: 28px;
  position: relative;

  &:hover {
    color: #000;
  }

  &:after {
    content: "";
    border-top: 15px solid #000000;
    border-left: 15px solid transparent;
    position: absolute;
    right: 5px;
    top: 5px;
  }

  @media (max-width: 768px) {
    height: 60px;
    width: 60px;
    line-height: 60px;
    font-size: 24px;

    &:after {
      border-top: 10px solid #000000;
      border-left: 10px solid transparent;
    }
  }
}

/* Social Sidebar */
.social-sidebar {
  position: absolute;
  left: 50px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  writing-mode: tb-rl;
  height: 100%;

  ul {
    margin: 0;
    text-align: center;

    li {
      display: inline-block;

      a {
        text-transform: uppercase;
        padding: 24px 15px;
        font-size: 15px;
        display: block;
        color: #fff;
        transition: all 0.3s;

        &:hover {
          background-color: var(--primary);
        }
      }
    }
  }

  @media (max-width: 1400px) {
    left: 20px;

    ul li a {
      padding: 15px 10px;
      font-size: 14px;
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
}

/* Keyframes for Additional Animations */
@keyframes move2 {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
