.booking-history {
  font-family: 'Arial', sans-serif;
  color: #333;
  padding: 20px;
  background-color: #f9f9f9;
}

.booking-history h1 {
  color: #f42222;
  text-align: center;
  margin-bottom: 30px;
}

.booking-history-parent header {
  background-color: #000;
  padding-top: 10%;
  left: 0;
  top: 0;
  width: 100%;
  position: relative;
}

.booking {
  border: 1px solid #f42222;
  padding: 20px;
  margin: 20px 0;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.booking:hover {
  transform: scale(1.02);
}

.booking h2 {
  color: #000;
  font-size: 1.5em;
  margin-bottom: 10px;
}

.booking p {
  margin: 10px 0;
  line-height: 1.6;
}

.booking a {
  color: #f42222;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.booking a:hover {
  color: #b71c1c;
}

.booking details {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #f42222;
  border-radius: 8px;
  background-color: #f9f9f9;
  font-size: 0.9em;
}

.booking summary {
  cursor: pointer;
  font-weight: bold;
  margin-bottom: 10px;
}

.booking details ul {
  list-style-type: none;
  padding: 0;
}

.booking details li {
  margin: 5px 0;
  padding: 8px 10px;
  border-bottom: 1px solid #ddd;
}

.booking details li:last-child {
  border-bottom: none;
}

.booking details .charge-desc {
  font-weight: bold;
}

.booking details .charge-amount {
  color: #f42222;
}

.booking .canceled {
  color: red;
  font-weight: bold;
}

.loading {
  text-align: center;
  font-size: 1.5em;
  color: #f42222;
}

.error {
  text-align: center;
  font-size: 1.5em;
  color: red;
}
