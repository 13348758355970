/* FAQ section styling */
.faq-section {
  background-color: #f8f9fa;
  padding: 60px 20px;
  margin-top: 40px;
}

.faq-section .container {
  max-width: 900px;
  margin: 0 auto;
}

.faq-section .heading-bx {
  margin-bottom: 40px;
}

.faq-section .heading-bx .title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  text-transform: uppercase;
}

.faq-section .faq-content {
  display: flex;
  flex-direction: column;
  gap: 30px;
  display: inline;
}

.faq-section .faq-item {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.faq-section .faq-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.faq-section .faq-subheading {
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 10px;
  color: #f42222;
}

.faq-section .faq-content {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #555;
}

/* Responsive Design for smaller devices */
@media (max-width: 768px) {
  .faq-section .heading-bx .title {
    font-size: 2rem;
  }

  .faq-section .faq-subheading {
    font-size: 1.4rem;
  }

  .faq-section .faq-content {
    font-size: 1rem;
  }
}

.faq-content span {
  display: inline; /* Ensure spans are inline */
}

.faq-content a {
  display: inline; /* Ensure links remain inline */
  text-decoration: underline; /* Optional: to highlight links */
}