/* 
   PartnerShipsSection basic styling for columns 
   (add this to your main stylesheet or a component-specific CSS file)
*/

/* Container for columns */
.partners-grid {
  display: flex;
  flex-wrap: wrap; /* Allows columns to wrap if there are too many */
  justify-content: center;
  gap: 30px;       /* Space between columns */
  margin-top: 20px;
}

/* Individual column */
.partners-column {
  list-style: none;  
  margin: 0;
  padding: 0;
}

/* Each list item (city or brand) */
.partners-column li {
  margin-bottom: 8px; /* Space between items */
}

/* Anchor links styling (optional override) */
.partners-column a {
  color: #333;              /* Text color */
  text-decoration: none;    /* Remove underline */
  transition: color 0.2s;
}

.partners-column a:hover {
  color: #007bff;           /* Hover color */
}
