/* how-ride-n-repair-works.css */

/* -- Wrapper Section -- */
.hrw-section {
  padding: 60px 15px;
  background-color: #f9f9f9;
}

.hrw-container {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

/* -- Section Title & Subtitle -- */
.hrw-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.hrw-subtitle {
  font-size: 1.125rem;
  color: #555;
  margin-bottom: 2.5rem;
}

/* -- Steps Wrapper -- */
.hrw-steps {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

/* -- Individual Step Layout -- */
.hrw-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  background-color: #fff;
  border-radius: 8px;
  padding: 1.5rem;
  gap: 1.5rem;
}

/* Step Image */
.hrw-step-image img {
  max-width: 100%;
  height: auto;
  border-radius: 6px;
  object-fit: cover;
}

/* Step Text Section */
.hrw-step-text {
  width: 100%;
}

.hrw-step-text h3 {
  font-size: 1.5rem;
  margin-bottom: 0.75rem;
  color: #333;
}

.hrw-step-text p {
  font-size: 1rem;
  line-height: 1.6;
  color: #666;
}

/* -- Media Queries for Larger Screens -- */
@media (min-width: 768px) {
  .hrw-step {
    flex-direction: row;
    text-align: left;
  }
  .hrw-step:nth-child(even) {
    flex-direction: row-reverse;
  }
  .hrw-step-image,
  .hrw-step-text {
    flex: 1;
  }
  .hrw-step-image {
    max-width: 50%;
  }
  .hrw-step-text {
    padding: 0 20px;
  }
}