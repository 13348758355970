// Header Logo
.menu-logo {
    display: table;
    float: left;
    vertical-align: middle;
    padding: 0;
    color: #EFBB20;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    width: 140px;
    height: 70px;
    position: relative;
    z-index: 9;
	a{
		display: table-cell;
		vertical-align: middle;
	}
	img {
		height: auto;
		width: 100%;
	}
	span {
		font-size: 20px;
		letter-spacing: 20px;
	}
	.sticky-logo{
		display: none; 
	}
}
.sticky-header.active{
	.sticky-logo{
		display: table-cell;
	}
	.main-logo{
		display: none;
	}
}

// Header
.header {
	position:relative;
	z-index:999;
	.menu-bar {
		background: #fff;
		width: 100%;
		position: relative;
	}
	.container{
		position: relative;
		@include respond('phone'){
			top: 30%;
		}
	}
	.container-fluid{
		padding-left:30px;
		padding-right:30px;
	}
	.secondary-menu {
		float: right;
		height: 70px;
		display: flex;
		align-items: center;
		position: relative;
		z-index: 9;
		padding-left: 15px;
		
		#quik-search-btn{
			font-size: 25px;
			line-height: 25px;
			color: #020d26;
			cursor: pointer;
			margin-right: 10px;
			border: 0;
			padding: 5px;
		}
		.btn{
			@include respond('phone'){
				padding-left:15px;
				padding-right:15px;
			}
		}
		@include respond('phone'){
			top: 3%;
			float: none;
		}
	}
}

// Navbar Toggler
.navbar-toggler {
    border: 0 solid #efbb20;
    font-size: 16px;
    line-height: 24px;
    margin: 27px 0px 25px 15px;
    padding: 0;
	float: right;
	outline: none;
}

// Menuicon
.menuicon{
	width: 20px;
	height: 18px;
	position: relative;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: .5s ease-in-out;
	-moz-transition: .5s ease-in-out;
	-o-transition: .5s ease-in-out;
	transition: .5s ease-in-out;
	cursor: pointer;
	
	span{
		display: block;
		position: absolute;
		height: 2px;
		width: 100%;
		border-radius: 1px;
		opacity: 1;
		left: 0;
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transition: .25s ease-in-out;
		-moz-transition: .25s ease-in-out;
		-o-transition: .25s ease-in-out;
		transition: .25s ease-in-out;
		background: #000;
		
		&:nth-child(1) {
			top: 0px;
		}

		&:nth-child(2) {
			top: 7px;
		}
		&:nth-child(3) {
			top: 14px;
		}
		
	}
	
	&.open span:nth-child(1) {
	  top: 7px;
	  -webkit-transform: rotate(135deg);
	  -moz-transform: rotate(135deg);
	  -o-transform: rotate(135deg);
	  transform: rotate(135deg);
	}
	&.open span:nth-child(2) {
	  opacity: 0;
	  left: -60px;
	}
	&.open span:nth-child(3) {
		top: 7px;
		-webkit-transform: rotate(-135deg);
		-moz-transform: rotate(-135deg);
		-o-transform: rotate(-135deg);
		transform: rotate(-135deg);
	}
}
.header-tb .menuicon span{
	background:#fff;
}

// Menu Links
.menu-links {
    padding: 0;
	
	&.show {
		@include respond('tab-port'){
			left: -1px;
			@include transitionSlow;
			margin:0;
			width: 280px;
		}
	}
	.menu-logo{
		display: none;
		@include respond('tab-port'){
			padding: 30px 20px;
			float: none;
			height: auto;
			width: 240px;
		}
	}
	.nav{
		float: right;
		padding: 0;
			
		&>li {
			margin: 0px;
			font-weight: 400;
			position: relative;
			&.has-mega-menu {
				position: inherit;
			}
			@include respond('tab-port'){	
				border-bottom: 1px solid #eee;	
				width: 100%;	
			}
			
			&>a {
				color: #020d26;
				font-size: 16px;
				padding: 27px 12px 27px 12px;
				cursor: pointer;
				font-weight: 600;
				display: inline-block;
				position: relative;
				border-width: 0 0 0 1px;
				text-transform: uppercase;
				line-height: 1;
				i{
					font-size: 9px;
					margin-left: 3px;
					margin-top: -3px;
					vertical-align: middle;
					
					@include respond('tab-port'){	
						right: 0;
						text-align: center;
						z-index: 3;
						float: right;
						font-size: 20px;
						margin: 5px 0 0;
					}
					&:before {
						@include respond('tab-port'){
							content: "\f105";
						}
					}
				}
				@include respond('tab-port'){	
					padding: 8px 0;
					display: block;	
					line-height: 30px;	
					color:#fff;
				}
			}
			.mega-menu,
			.sub-menu {
				background-color: #ffffff;
				display: block;
				left: 0;
				list-style: none;
				opacity: 0;
				padding: 5px 0;
				position: absolute;
				visibility: hidden;
				width: 220px;
				z-index: 10;
				margin-top: 20px;
				box-shadow:0px 1px 40px 0px rgba(0, 0, 0, 0.1);
				@include transitionSlow;
				@include respond('tab-port'){	
					display: none;
					position: static;
					visibility: visible;
					width: auto;
					background: transparent;
					box-shadow: none;
					margin: 0;
					border-top:1px solid rgba(255, 255, 255, 0.1);
					padding:0;
				}
				
				li {
					position: relative;
					a {
						color: #000;
						display: block;
						font-size: 15px;
						padding:10px 25px;
						text-transform: capitalize;
						position:relative;
						transition: all 0.15s linear;
						-webkit-transition: all 0.15s linear;
						-moz-transition: all 0.15s linear;
						-o-transition: all 0.15s linear;
						font-weight: 400;
						@include respond('tab-port'){
							padding:8px 0px 8px 20px;
							border-bottom:1px solid rgba(255,255,255,0.1);
							&:after{
							//	content: "\f105";
								font-family: FontAwesome;
								width: auto;
								height: auto;
								top: 1px;
								color: #000;
								font-size: 16px;
								position: absolute;
								left: 0;
								font-weight: 700;
							}
						}
					}
					&:hover > a{
						background-color: #F2F2F2;
					}
				}
				li>.sub-menu {
					left: 220px;
					@include transitionMedium;
				}
			}
			.sub-menu{
				li .fa {
					color: inherit;
					display: block;
					float: right;
					font-size: 16px;
					opacity: 1;
					margin-top: 4px;	
				}				
			}
			.mega-menu {
				display: flex;
				right: 0px;
				width: 100%;
				z-index: 9;
				padding: 0;
				&>li {
					display: block;
					padding: 30px 0 20px;
					position: relative;
					vertical-align: top;
					width: 25%;
					@include respond('tab-port'){
						width: 100%;
						padding:0;
					}
					&>a {
						color: #000;
						display: block;
						padding: 0 20px;
						font-size: 16px;
						font-weight: 600;
						border-bottom: 1px solid rgba(0,0,0,0.1);
						padding-bottom: 15px;
						margin-bottom:15px;
						&>i{
							font-size: 14px;
							margin-right: 5px;
							text-align: center;
							width: 15px;
						}
						@include respond('tab-port'){
							display:none;
						}
					}
					&:after {
						content: "";
						background-color: rgba(0, 0, 0, 0.1);
						position: absolute;
						right: 0px;
						top: 0px;
						display: block;
						width: 1px;
						height: 100%;
						@include respond('tab-port'){
							content:none;
						}
					}
					&:last-child:after {
						display: none;
					}
					&:hover > a{
						background-color: transparent;
					}
					li i{
						font-size: 14px;
						margin-right: 5px;
						text-align: center;
						width: 15px;
					}
				}
				@include respond('tab-port'){	
					display: none;
					padding:0;
				}
			}
			&:hover{
				&>.mega-menu,
				&>.sub-menu{
					opacity: 1;
					visibility: visible;
					margin-top: 0;
					@include transitionMedium;
					
					li:hover > .sub-menu{
						opacity: 1;
						visibility: visible;
						margin-top: 0;
						top: -1px;
					}
				}
			}
			&.open{
				@include respond('tab-port'){
					.mega-menu,
					.sub-menu{
						display:block;
						opacity:1;
					}
				}
			}
		}
		@include respond('tab-port'){
			float: none;
			width: 70%;
			min-width: 300px;
		}
	}
	.social-media{
		text-align: center;
		margin-top: 50px;
		display: none;
	}
	.menu-close{
		height: 40px;
		color: #fff;
		background-color: var(--primary);
		line-height: 44px;
		text-align: center;
		width: 40px;
		position: absolute;
		right: 0;
		top: 0;
		display: none;
		z-index: 9999;
		margin-top: 10%;
	}
	@include respond('tab-port'){
		overflow-y: scroll;
		position: fixed;
		width: 60px;
		left:-280px;
		height:100vh;
		@include transitionMedium;
		top:0;
		background-color:#fff;
		margin:0;
		z-index:99;
		
		.social-media,
		.menu-close{
			display: block;
		}
	}
}

// Top Ear
.top-bar {
    background-color: #191f23;
    color: #fff;
    padding: 8px 0;
	
	@include respond('phone'){
		display:none;
	}
	.topbar-left {
		float: left;
		li{
			padding-right:15px;
		}
	}
	.topbar-right {
		float: right;
		li{
			padding-left:15px;
		}
	}
	
	.topbar-center,
	.topbar-left,
	.topbar-right {
		padding-left: 15px;
		padding-right: 15px;
		ul{
			list-style:none;
			margin:0;
			padding:0;
			li{
				display:inline-block;
				font-size: 15px;
				@include respond('tab-land'){
					font-size: 13px;
				}
			}
			a{
				color:inherit;
			}
			i{
				color:$primary;
				transform: scale(1.3);
				-moz-transform: scale(1.3);
				-webkit-transform: scale(1.3);
				-ms-transform: scale(1.3);
				-o-transform: scale(1.3);
				margin-right: 5px;
			}
		}
	}
}

// Header Transparent
.header-transparent{
	position:absolute;
	top:0;
	left:0;
	width:100%;
	@include respond('phone'){
		top: 3%;
	}
	
	.menuicon{
		span{
			background-color: #fff;
		}
	}
	.top-bar,
	.menu-bar{
		background: transparent;
		border-bottom:1px solid rgba(255,255,255,0.1);
	}
	.top-bar{
	    color: #777;
		border-bottom:1px solid rgba(0,0,0,0.1);
		@include respond('phone'){
			display:none;
		}
	}
	.sticky-header.active{
		.menu-links {
			.nav{
				&>li {
					&>a {
						color:#000;	
					}	
				}	
			}	
		}
		.secondary-menu{
			.btn-outline-light{
				color: #000;
				border-color: #000;
				&:hover{
					color: #fff;
					background-color: #000;
				}
			}
		}
		.menuicon{
			span{
				background-color: #000;
			}
		}
	}
	@include respond('tab-port-min'){
		.menu-links {
			.nav{
				&>li {
					&>a {
						color:$white;	
					}	
				}	
			}
		}
	}
}

// Sticky Header
.sticky-header.active{
	.menu-logo{
		@include durationMedium;
	}
	.menu-bar{
		position: fixed;
		top: 0;
		left: 0;
		box-shadow:0 0 50px 0 rgba(0, 0, 0, 0.1);
		transition: all 0.5s;
	}
	.logo-change{
		.logo1{
			display:none;
		}
		.logo2{
			display: block;
		}
	}
}
.header-transparent{
	.sticky-header.active{
		.menu-bar{
			position:fixed;
			background-color: #fff;
			@include respond('phone'){
				height: 100px;
			}
		}
	}
} 


// Responsive Size Nav
@include respond('tab-port'){
	.rs-nav{
		.menu-links{
			position: fixed;
			width: 60px;
			left:-105%;
			height:100vh;
			@include transitionMedium;
			top:0;
			background-color:#171a1e;
			margin:0;
			z-index:99;
			overflow-y: scroll;
			flex-direction: column;
			display: flex;
			flex-direction: column;
			justify-content: unset !important;	
			    
			li.open a{
				position:relative;
			}
			.menu-logo {
				display: block;
				float: none;
				height: auto;
				max-width: 100%;
				padding: 20px 15px;
				width: 100%;
				text-align: center	;
				img{
					max-width: unset;
					width: 130px;
					vertical-align: middle;
				}
				a{
					display:inline-block;
				}
			}
			
			&.nav-dark{
				background-color:#202020;
			}
			&.show {
				left: 0;
				@include transitionSlow;
				margin:0;
				width: 100%;
				padding: 15px 15px 5px 15px;
			}
		}
		.sticky-header.active{
			.menu-links{
				.nav{
					height:auto;
				}
			}
		}
		.social-media{
		    margin-top: auto;
			padding: 30px 0;
		}
	}
	.navbar-nav{
		height:auto;
	}
	
	// Header Transparent
	.menu-links{
		.nav > li{
			border-color: rgba(255,255,255,0.1);
			&:hover,
			&.active{
				& > a{
					color:#fff;
					border-color: rgba(255,255,255,0.1);
				}
			}
			.mega-menu li a, 
			.sub-menu li a{
				color:rgba(255,255,255,0.7);
			}
			.mega-menu li:hover > a, 
			.sub-menu li:hover > a {
				background-color: transparent;
			}
		}
	}
	.header-transparent .sticky-header.active .menu-links .nav > li > a {
		color: #fff;
	}
	
}

// Header Style 1
.header{
	&.style-1{
		.menu-bar{
			padding: 25px 0;
			@include transitionMedium;
		}
		.navbar-toggler {
			margin: 18px 0px 18px 35px;
		}
		@include respond('tab-port-min'){
			.menu-links{
				.nav{
					& > li{
						& > a{
							border: 3px solid;
							border-color: transparent;
							border-width: 3px 0;
							padding: 18px 20px;
							@include transitionMedium;
							
							&:before,
							&:after{
								content: "";
								position: absolute;
								display: none;
								@include transitionMedium;
							}
							&:before{
								border-top: 15px solid $primary;
								border-left: 15px solid transparent;
								top: 0;
								right: 0;
							}
							&:after{
								border-top: 15px solid transparent;
								border-left: 15px solid $primary;
								left: 0;
								bottom: 0;
							}
						}
						&.active,
						&:hover{
							& > a{
								border-color: $primary;
								&:before,
								&:after{							
									display: block;
								}
							}
						}
					}
				}
			}
		}
		.menu-logo {
			height: 58px;
		}
		.secondary-menu {
			height: 58px;
		}
		.account-btn{
			margin-left: 50px;
		}
		@include respond('tab-land'){
			.phone-bx{
				display: none;
			}
		}
		.sticky-header.active{
			.phone-bx{
				.number{
					color: #000;
				}
			}
			.menu-bar {
				padding: 10px 0;
			}
		}
		@include respond('tab-port'){
			.menu-bar{
				padding: 10px 0;
			}
		}
	}
}
.phone-bx{
	display: flex;
    align-items: center;
	.icon{
		background-color: $primary;
		position: relative;
		height: 50px;
		width: 50px;
		text-align: center;
		line-height: 50px;
		color: #fff;
		font-size: 24px;
		margin-right: 12px;
		&:before{
			content: "";
			position: absolute;
			border-top: 15px solid #fff;
			border-left: 15px solid transparent;
			top: 0;
			right: 0;
		}
	}
	p{
		font-size: 14px;
		font-weight: 600;
		margin-bottom: 0;
	}
	.number{
		margin-bottom: 0;
		color: #fff;
	}
}
.account-btn{
	background-color: $primary;
	transform: skew(-28deg);
	padding: 5px;
	.btn{
		padding: 8px ​24px;
		span{
			transform: skew(28deg);
			display: block;
		}
	}
}