.terms-element-list {
  list-style-type: disc;
  margin-left: 10px;
}

.terms-subelement-list {
  list-style-type: square;
  margin-left: 20px;
}

.terms-subsubelement-list {
  list-style-type: circle;
  margin-left: 30px;
}