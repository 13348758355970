.app-download-section {
  background-color: #f8f9fa;
  padding: 60px 20px;
}

.heading-bx {
  margin-bottom: 40px;
}

.heading-bx .title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.heading-bx .subtitle {
  font-size: 1.2rem;
  color: #666;
}

.app-links-row {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 30px;
  flex-wrap: wrap;
}

.app-link-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.app-link-card:hover {
  transform: translateY(-5px);
}

.app-link {
  display: block;
  text-align: center;
  padding: 20px;
  text-decoration: none;
}

.app-icon {
  margin-bottom: 15px;
}

.app-text {
  font-size: 1.2rem;
  font-weight: 600;
  color: #f42222;
}

.app-link-card img {
  max-width: 120px;
}

@media (max-width: 768px) {
  .app-link-card {
    width: 100%;
  }

  .heading-bx .title {
    font-size: 2rem;
  }

  .heading-bx .subtitle {
    font-size: 1rem;
  }
}
