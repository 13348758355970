/* src/components/LazyGoogleMaps.scss */

.lazy-google-maps {
  position: relative;
  width: 100%;
  height: 100%;

  .show-map-button {
    width: 100%;
    height: 100%;
    background-color: #d4b58e; /* Google Blue */
    color: white;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #f5cb98;
    }
  }

  iframe {
    width: 100%;
    height: 100%;
  }
}
