/* .mechanic-entry-form {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.mechanic-entry-form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.mechanic-entry-form-group {
  width: calc(33.33% - 10px);
  margin-bottom: 10px;
}

label {
  display: block;
  margin-bottom: 5px;
}

select,
input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
} */

button {
  background-color: #f44336;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #d32f2f;
}

/* .response {
  margin-top: 20px;
  padding: 10px;
  background-color: #f3f3f3;
  border: 1px solid #ddd;
  border-radius: 5px;
} */

/* mechanic-entry-form.css */

.mechanic-entry-form {
  max-width: 800px;
  margin: 0 auto;
  padding: 30px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;

  font-family: 'Arial', sans-serif;
  color: #333;
}

.mechanic-entry-form h4 {
  color: #f42222;
  margin-bottom: 20px;
  text-align: center;
}

.mechanic-entry-form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

.mechanic-entry-form-group {
  flex: 1 1 calc(33.33% - 20px);
  margin: 10px;
}

.mechanic-entry-form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

.mechanic-entry-form-group input[type="text"],
.mechanic-entry-form-group input[type="number"],
.mechanic-entry-form-group select {
  width: 100%;
  padding: 8px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.mechanic-entry-form-group input[type="checkbox"] {
  margin-right: 8px;
}

.mechanic-entry-form-section {
  margin-top: 30px;
}

.mechanic-entry-form-section h5 {
  color: #f42222;
  margin-bottom: 15px;
}

.mechanic-entry-form-subsection {
  padding-left: 20px;
  border-left: 2px solid #f42222;
  margin-bottom: 20px;
}

.checkbox-group {
  display: flex;
  flex-wrap: wrap;
}

.checkbox-option {
  width: calc(50% - 10px);
  margin: 5px;
}

.checkbox-option label {
  display: flex;
  align-items: center;
}

.checkbox-option input[type="checkbox"] {
  margin-right: 8px;
}

/* button {
  display: block;
  width: 200px;
  margin: 0 auto;
  background-color: #f42222;
  color: #fff;
  border: none;
  border-radius: 25px;
  padding: 12px 0;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #cc1e1e;
} */

.response {
  margin-top: 30px;
  padding: 15px;
  background-color: #f9f9f9;
  border: 1px solid #f42222;
  border-radius: 8px;
}

.response p {
  margin: 0;
  font-weight: bold;
  color: #333;
}

@media (max-width: 768px) {
  .mechanic-entry-form-group {
    flex: 1 1 100%;
    margin: 10px 0;
  }

  .checkbox-option {
    width: 100%;
  }
}
