/* src/markup/elements/Chatbot.css */

.chat-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
}

.chat-icon img {
  width: 100px;
  height: 100px;
}

.chat-options {
  position: fixed;
  bottom: 80px;
  right: 20px;
  display: flex;
  flex-direction: column;
}

.chat-option-button {
  background-color: #f42222;
  color: #fff;
  border: none;
  margin-bottom: 10px;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  border-radius: 5px;
}

.chat-option-button img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.verification-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  width: 90%;
  max-width: 400px;
  border-radius: 10px;
  text-align: center;
}

.modal-content .logo {
  width: 150px;
  margin-bottom: 20px;
  align-self: center;
}

.input-group {
  margin-bottom: 15px;
  flex-wrap: nowrap;
}

.input-group input {
  /* width: calc(100% - 150px); */
  padding: 10px;
  margin-right: 5px;
}

.input-group button {
  padding: 10px;
  background-color: #f42222;
  color: #fff;
  border: none;
  cursor: pointer;
}

.error {
  color: red;
  font-size: 14px;
}

.start-chat-button {
  background-color: #f42222;
  color: #fff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.chatbot-interface {
  position: fixed;
  bottom: 0;
  right: 20px;
  width: 90%;
  max-width: 400px;
  height: 45%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px 10px 0 0;
  display: flex;
  flex-direction: column;
}

.chat-header {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chat-header .logo-small {
  width: 100px;
  margin-right: 10px;
}

.chat-header h6 {
  flex-grow: 1;
  text-align: center;
  justify-content: center;
  margin: 0;
}

.close-button {
  background: #f42222;
  border: none;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
}

.chat-body {
  flex-grow: 1;
  padding: 10px;
  overflow-y: auto;
  background-color: #f9f9f9;
}

.chat-message {
  margin-bottom: 10px;
  max-width: 80%;
}

.user-message {
  align-self: flex-end;
  background-color: #f42222;
  color: #fff;
  padding: 10px;
  border-radius: 10px 10px 0 10px;
}

.bot-message {
  align-self: flex-start;
  background-color: #eee;
  padding: 10px;
  border-radius: 10px 10px 10px 0;
}

.chat-footer {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ccc;
}

.chat-footer input {
  flex-grow: 1;
  padding: 10px;
}

.chat-footer button {
  padding: 10px;
  background-color: #f42222;
  color: #fff;
  border: none;
  cursor: pointer;
  margin-left: 5px;
}

.loading-message {
  font-style: italic;
  color: #888;
}
