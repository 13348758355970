/* MechanicDashboard.css */

/* General styles */
body {
    margin: 0;
    padding: 0;
    overflow: auto;
}

.mechanic-dashboard {
  padding: 20px;
  overflow: auto;
}

h2 {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.disabled-button {
  opacity: 0.5; 
  pointer-events: none; 
  cursor: not-allowed;
}

.booking-card {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.booking-id {
  font-size: 18px;
  margin-bottom: 10px;
  color: #f44336;
  cursor: pointer;
  text-decoration: underline;
}

.service-type {
  font-weight: bold;
  margin-bottom: 10px;
}

.time {
  color: #777;
  margin-bottom: 10px;
}

.location {
  margin-bottom: 10px;
}

.paid-status {
  margin-bottom: 10px;
}

.amount {
  font-weight: bold;
}

.logout-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.logout-button:hover {
  background-color: #d32f2f;
}

.logout-button:focus {
  outline: none;
}

/* Modal styles */
.modal {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent overlay backdrop */
  z-index: 999;
  overflow: auto;
}

/* .modal-content h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff27;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 90%;
  max-height: 90%;
  overflow-x: hidden;
  overflow-y: auto;
} */

/* Mobile styles */
@media (max-width: 480px) {
  .booking-card {
    padding: 16px;
  }

  h2 {
    font-size: 20px;
  }

  .modal-content {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    top: 0;
    left: 0;
    transform: none;
    border-radius: 0;
  }
}

/* Add this CSS to your stylesheet */
.table-container {
  overflow-x: auto; /* Enable horizontal scrolling on small screens */
}

/* .part-table {
  width: 100%;
  border-collapse: collapse;
}

.part-table th,
.part-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ccc;
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis; 
} */

.part-table {
  max-width: 500px; /* Set your desired maximum width */
  width: 100%; /* Fill the container width if it's smaller than the maximum width */
  table-layout: fixed; /* Ensure the table layout is fixed for width control */
}

.part-table thead tr th {
  width: 33%;
  max-width: 100px;
  text-align: center;
  white-space: nowrap;
}

.part-table tbody tr td {
  text-align: center;
}

.part-table tbody tr td input {
  width: 125px;
}

.confirmation-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.confirmation-modal h3 {
  margin-top: 0;
}

.confirmation-modal p {
  margin-bottom: 15px;
}

.confirmation-modal button {
  margin-right: 10px;
  padding: 8px 20px;
  border: none;
  background-color: #f42222;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

.confirmation-modal button:hover {
  background-color: #d32f2f;
}

.date-div {
  display: inline-flex;
}

.date-div > div > div > div > span {
  z-index: 0;
}