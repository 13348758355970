// Team Member
.team-member{
	position: relative;
	text-align: center;
	
	.team-media{
		position: relative;
		img{
			width: 70%;
		}
	}
	.team-info {
		span{
			font-weight: 600;
		}
	}
	
	&.style-1{
		overflow:hidden;
		.team-info {
			text-align: left;
			padding: 30px;
			position: absolute;
			background-color: #fff;
			width: 100%;
			height: 100%;
			right: -100%;
			top:0;
			opacity: 1;
			@include durationMedium;
			z-index: 1;
			
			.title{
				border-bottom:1px solid #eee;
				padding-bottom:10px;	
				margin-bottom: 15px;
					
				a{
					color:$black;
				}
			}
		}
		&:hover{
			box-shadow:0 0 30px 0 rgba(0,0,0,0.1);
			.team-info {
				opacity: 1;
				right: 0;
			}
		}
		@media only screen and (min-width: 991px) and (max-width: 1100px){
			.team-info {
				padding: 15px;
				.title{
					font-size: 20px;
				}
			}
		}
		@media only screen and (min-width: 591px) and (max-width: 691px){
			.team-info {
				padding: 18px;
			}
		}
		@include respond('phone'){
			.team-info {
				.title{
					padding-bottom:15px;
					margin-bottom:20px;
				}
				.icon-list {
					margin-bottom: 20px;
				}
			}
		}
	}
	&.style-2{
		background: #fff;
		.team-media{
			overflow: hidden;
		}
		.team-info{
			border-bottom: 4px solid var(--primary);
			text-align: center;
			padding: 20px 10px;
		}
		.social-media{
			position: absolute;
			bottom: -60px;
			left: 0;
			display: block;
			width: 100%;
			text-align: center;
			@include transitionMedium;
		}
		.title{
			margin-bottom: 0;
			margin-bottom: 5px;
		}
		&:hover{
			.social-media{
				bottom: 30px;
			}
		}
	}	
}
.social-media{
	padding: 0;
	margin: 0 -2px;
	list-style:none;
	
	li{
		display: inline-block;
		padding: 0 2px;
		a{
			height: 40px;
			width: 40px;
			border-radius: 0;
			padding: 8px 10px;
		}	
	}	
}
.icon-list{
	padding: 0;
	margin: 0;
	list-style:none;
	margin-bottom:15px;
	li{
		display: block;
		padding: 3px;
		font-size:14px;
		i{
			color: $primary;
			margin-right: 5px;
		}
	}
}