.service-menu{
	margin: 0;
    padding: 0;
    list-style: none;
    background: #f42222;
    padding: 30px;
    box-shadow: 0 8px 60px -25px #f42222;
	li{
		a{
			display: block;
			padding: 15px 50px 15px 20px;
			text-transform: capitalize;
			font-weight: 600;
			position: relative;
			z-index: 1;
			transition: all 0.5s;
			-moz-transition: all 0.5s;
			-webkit-transition: all 0.5s;
			-ms-transition: all 0.5s;
			-o-transition: all 0.5s;
			border-bottom: 1px solid rgba(255, 255, 255, 0.15);
			color: #fff;
			font-weight: 500;
			font-size: 16px;
		}
		&:last-child a{
			border-bottom:0;
		}
		a{ 
			i{
				color: #fff;
				font-size: 18px;
				line-height: 16px;
				text-align: center;
				border-radius: 35px;
				position: absolute;
				right: 20px;
				top: 20px;
				padding-left: 2px;
			}
		}
		&.active,
		&:hover{
			a{
				background-color: #fff;
				color: #000;
				i{
					color: #000;
				}
			}
		}
	}
	&.style-1{
		li a i {
			font-size: 24px;
		}
	}
	@include respond('tab-land'){
		padding: 15px;
	}
}
.left-border-1{
	padding-left: 30px;
    border-left: 1px solid rgba(0,0,0,0.1);
}
.contact-infolist{
	margin: 0 0 40px;
    padding: 25px 0 5px;
    list-style: none;
    border-top: 1px solid rgba(0,0,0,.1);
    border-bottom: 1px solid rgba(0,0,0,.1);
	li {
		position: relative;
		padding: 0 20px 20px 80px;
		img {
			position: absolute;
			left: 0;
			top: 10px;
			width: 60px;
		}
		.contact-title {
			color: #020d26;
			font-size: 20px;
			line-height: 28px;
			font-weight: 600;
			margin-bottom: 2px;
		}
		a, 
		p {
			font-size: 16px;
			line-height: 24px;
			color: #555;
			font-weight: 400;
			margin-bottom: 5px;
		}
	}
}

/* Shared styles for perks and checklist items */
.flex-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.flex-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.flex-item::before {
  content: "\2022"; /* Bullet point character */
  color: #5cb85c; /* Green color for the bullet point */
  margin-right: 8px;
}

/* Time and price section */
.time-and-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.time-taken {
  display: flex;
  align-items: center;
}

.time-taken svg {
  margin-right: 5px;
}

/* Checklist section */
.checklist {
  padding: 15px;
}

.checklist details {
  margin-left: 10px;
}

.checklist ul {
  list-style-type: none;
}

.checklist li {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.check-icon {
  margin-right: 5px;
}

/* Price information */
.strike-through {
  text-decoration: line-through;
  color: #999;
	padding-right: 10px;
}

// .actual-price {
//   color: #333; /* Color for the actual price text */
// }

/* New layout adjustments */
.icon-content {
  position: relative;
  padding: 1%;
	height: fit-content;
}

.icon-content .perks ul {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.icon-content .perks li {
  margin-bottom: 8px;
}

.price-info {
  text-align: left;
	font-size: x-large;
	width: fit-content;
}

.add-to-cart-btn {
  margin: 5px;
}

.add-to-cart-btns {
  bottom: 0px;
  right: 0;
	font-size: x-large;
}

.price-buttons {
	display: inline-flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

/* Checklist styling */
.checklist details {
  margin-top: 15px;
}

.checklist summary {
  cursor: pointer;
}

.checklist ul.checklist-items {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.checklist li {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  width: calc(33.33% - 10px);
}

.ttr-tilte {
	text-decoration: underline;
}

.checklist-item::before {
  content: "\2713"; /* Checkmark character */
  color: #5cb85c ;
  margin-right: 8px;
}

.plus-icon::before {
  content: "\002B"; /* Plus icon */
  color:  #777777;
  margin-right: 8px;
}

.minus-icon::before {
  content: "\2212"; /* Minus icon */
  color: #777777;
  margin-right: 8px;
}

/* Additional styles for expanded checklist */
.checklist ul.expanded {
  flex-wrap: nowrap;
}

.checklist ul.expanded li {
  width: calc(50% - 10px); /* Two items per row with some gap */
}

.services-icon {
	margin: 0 auto;
	height: 150px !important;
	width: 150px !important;
}

.icon-content .time-and-price {
  position: absolute;
  top: 70px;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

@media (max-width: 768px) {

	.add-to-cart-btns {
		flex-direction: column;
		display: flex;
		font-size: medium;
	}

	.services-icon {
		height: 100px !important;
		width: 100px !important;
	}
	
	.checklist {
		padding: 0px;
	}
	
	.checklist li {
		width: calc(50% - 10px);
	}

	.icon-content .time-and-price {
		top: 35px;
	}
}