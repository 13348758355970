html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.places-container {
  position: relative;
  left: 43%;
  bottom: 2%;
  transform: translateX(-50%);
  z-index: 10;
  width: 300px;
}

.map-container {
  width: 100%;
  height: 50vh;
}

.combobox-input {
  width: 125%;
  padding: 0.5rem;
}

.results-popover {
  background: white;
  cursor: pointer;
}

.results-popover {
  ul {
    li {
      border-bottom: 1px solid gray;
    }
    li:hover {
      background-color: #f42222;
      color: white;
    }
  }
}