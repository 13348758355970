/* Introduction section styling */
.introduction-section {
  background-color: #f8f9fa;
}

.introduction-section .container {
  max-width: 900px;
  margin: 0 auto;
}

.introduction-section .heading-bx {
  margin-bottom: 40px;
}

.introduction-section .heading-bx .title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  text-transform: uppercase;
}

.introduction-section .content {
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

.introduction-section .intro-text {
  font-size: 1.3rem;
  line-height: 1.8;
  color: #555;
  font-weight: 500;
  margin-bottom: 20px;
  display: inline;
}

.introduction-section .city-text {
  font-size: 3.2rem;
  color: #f42222;
  font-weight: 600;
}

/* Add media queries for responsiveness */
@media (max-width: 768px) {
  .introduction-section .heading-bx .title {
    font-size: 2rem;
  }

  .introduction-section .intro-text {
    font-size: 1.1rem;
  }

  .introduction-section .city-text {
    font-size: 2rem;
  }
}

.intro-text span {
  display: inline; /* Ensure spans are inline */
}

.intro-text a {
  display: inline; /* Ensure links remain inline */
  text-decoration: underline; /* Optional: to highlight links */
}
