@media (max-width: 991px) {
  .modal-popup {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100% !important;
    height: 100% !important;
    background-color: rgba(0, 0, 0, .80);
    z-index: 9999;
    overflow: hidden;
    &-inner {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 90%;
      width: 100%;
      background-color: #fff;
      flex-direction: column;
    }
    &__photo {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      height: 100%;
      overflow: hidden;
      img {
        width: auto;
        height: 100%;
      }
    }
    &__text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      padding: 1rem;
      h1 {
        font-size: 2rem;
        font-weight: 600;
        margin-bottom: 2rem;
        text-transform: uppercase;
        color: #0A0A0A;
      }
      p {
        font-size: .875rem;
        color: #686868;
        line-height: 1.5;
      }
    }
    &:target {
      visibility: visible;
      opacity: 1;
      .popup-inner {
        bottom: 0;
        right: 0;
        transform: rotate(0);
      }
    }
    &__close {
      position: absolute;
      right: 0rem;
      top: 0rem;
      width: 3rem;
      height: 3rem;
      font-size: .875rem;
      font-weight: 300;
      border-radius: 100%;
      background-color: #0A0A0A;
      z-index: 4;
      color: #fff;
      line-height: 3rem;
      text-align: center;
      cursor: pointer;
      text-decoration: none;
    }
  }
}

@media (min-width: 992px) {
  .modal-popup {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100% !important;
    height: 100% !important;
    background-color: rgba(0, 0, 0, .80);
    z-index: 9999;
    overflow: hidden;
    &-inner {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 50%;
      width: 100%;
      background-color: #fff;
    }
    &__photo {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      width: 40%;
      height: 100%;
      overflow: hidden;
      img {
        width: auto;
        height: 100%;
      }
    }
    &__text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 60%;
      height: 100%;
      padding: 1rem;
      h1 {
        font-size: 2rem;
        font-weight: 600;
        margin-bottom: 2rem;
        text-transform: uppercase;
        color: #0A0A0A;
      }
      p {
        font-size: .875rem;
        color: #686868;
        line-height: 1.5;
      }
    }
    &:target {
      visibility: visible;
      opacity: 1;
      .popup-inner {
        bottom: 0;
        right: 0;
        transform: rotate(0);
      }
    }
    &__close {
      position: absolute;
      right: 0rem;
      top: 0rem;
      width: 3rem;
      height: 3rem;
      font-size: .875rem;
      font-weight: 300;
      border-radius: 100%;
      background-color: #0A0A0A;
      z-index: 4;
      color: #fff;
      line-height: 3rem;
      text-align: center;
      cursor: pointer;
      text-decoration: none;
    }
  }
}