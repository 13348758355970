// help-bx
.help-bx{
	box-shadow: 0 20px 60px 0 rgba(0, 0, 0, 0.1);
	.info{
		padding: 15px 25px 35px 25px;
		text-align: center;
		position: relative;
		z-index: 1;
		img{
			max-width: 60px;
			margin-bottom: 20px;
		}
		&:before,
		&:after{
			content: "";
			background: #fff;
			position: absolute;
			top: -25px;
			width: 100%;
			height: 80px;
			left: 0;
			transform: skewY(-8deg);
			z-index: -1;
		}
		&:before{
			background: #fff;
			top: -32px;
			transform: skewY(-5deg);
			height: 40px;
		}
	}
	p{
		font-size: 16px;
		line-height: 24px;
		margin-bottom: 20px;
	}
}

//services list
.services-list{
	color: darkslategray;
}

// brochure
.brochure-bx{
	padding: 30px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    z-index: 1;
    background: #fff;
    box-shadow: 0 20px 60px 0 rgba(0, 0, 0, 0.1);
	
	.title-head {
		border-bottom: 1px solid rgba(0,0,0,0.15);
		padding-bottom: 8px;
		position: relative;
		margin-bottom: 30px;	
		&:after {
			background:var(--primary);
			content:"";
			width:30px;
			height:3px;
			position:absolute;
			bottom:-2px;
			left:0;
		}
	}
}
.download-link{
    position: relative;
    display: block;
    padding-left: 45px;	
    margin-top: 20px;	
	.title{
		font-size: 16px;
		margin-top: 0;
		margin-bottom: 0px;
		line-height: 22px;
	}
	span{
		color: #888;
		font-size: 14px;
		line-height: 18px;	
	}
	img{
		width: 45px;
		position: absolute;
		left: -5px;
		top: 0;
	}
}

.vehicle-type-buttons {
  display: flex;
  margin-bottom: 10px;
	margin-top: 10px;
	justify-content: center;
	z-index: 10;
	position: relative;
}

.cc-range-buttons {
	flex-direction: column;
	align-items: center;
}

.cc-range-button-group {
	display: flex;
}

.vehicle-type-button {
  background-color: white;
  color: black;
}

.vehicle-services-header {
	height: auto;
}

.vehicle-type-button.active, .vehicle-type-button:hover {
  background-color: #f42222;
  color: white;
}

.vehicle-selection-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
	margin-top: 5%;
	margin-bottom: 5%;
}

.cc-range-button  {
	flex: 1 0 calc(33.333% - 10px);
	width: 100px;
	margin: 5px;
}

.vehicle-dropdown {
  position: relative;
  display: inline-block;
	padding-top: 10px;
	width: 200px;
}

.dropdown-label {
	color: black;
  padding: 10px;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.arrow-down {
  border: solid #000;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
}

.dropdown-options {
	color: black;
  display: block;
  position: absolute;
	width: inherit;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000000;
  max-height: 200px;
  overflow-y: auto;
}

.option-item {
  padding: 10px;
  cursor: pointer;
}

.option-item:hover {
  background: #f1f1f1;
}

.brand-option {
  display: flex;
  align-items: center;
}

.brand-image {
  width: 30px;
  margin-right: 10px;
}

.specific-vehicle-type button {
	font-size: x-large;
}

.vertical-dropdown-container {
  align-items: center;
	width: 200px;
}

/* Add styles for mobile devices */
@media (max-width: 768px) {
	.vehicle-selection-row {
		display: block;
	}

	.dropdown-options {
		max-height: 150px;
	}

  .vehicle-type-buttons {
		margin-bottom: 5px;
	};

	.specific-vehicle-type button {
		font-size: medium;
	}

	.selected-option {
		font-size: 11px;
	}

  .vehicle-type-button {
    font-size: 11px;
    padding: 8px 12px;
  }

	.vehicle-dropdown {
		width: 150px;
		padding: 5px;
	}

  .dropdown-label {
    padding: 3px;
		font-size: 10px;
  }

  .arrow-down {
    padding: 2px;
  }

  .dropdown-options {
    width: 100%;
    max-height: 200px; 
    overflow-y: auto;
		font-size: 11px;
  }

  .option-item {
    padding: 8px; /* Adjust padding for mobile */
  }

  .brand-image {
    width: 20px;
		margin-right: 6px;
  }

  .vertical-dropdown-container {
    width: 100%; /* Occupy full width on small screens */
    text-align: center; /* Center text for better alignment */
  }

  .vehicle-image-container {
    margin-top: 10px; /* Add margin to adjust the placement */
  }
}
