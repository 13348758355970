.india-header {
  background-color: white !important;
  color: black !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-icon {
  cursor: pointer;
  font-size: 24px;
  font-weight: bold;
  align-self: flex-start;
  margin-right: 10px;
  position: absolute;
  top: 10px;
  right: 0;
}

.close-button-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding: 10px 0;
  position: relative;
  z-index: 11001;
}

.close-button-row button {
  padding: 10px 20px;
  cursor: pointer;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 5px;
  outline: none;
  position: relative;
  z-index: 11002;
}

@media (max-width: 768px) {
  .cities-modal {
    width: 90%;
    max-width: 90%;
    height: auto;
    max-height: 90%;
    overflow-y: auto;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }
}

.cities-modal {
  padding: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
  display: flex;
  flex-direction: column; /* Stack children vertically */
  flex-direction: column;
  background-color: white;
  color: black;
  position: fixed;
  z-index: 11000;
  top: 25%;
  left: 20%;
  width: 60%;
  height: auto;
  display: flex;
  overflow-y: auto;
}

.india-flag {
  width: 50px;
  height: auto;
  margin-bottom: 1%;
}

.modal {
  background-color: #fff; 
  padding: 20px;
  border-radius: 8px;
  width: 70%;
  max-height: 80vh;
  overflow-y: auto;
}

.city-list {
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.city-item {
  flex: 0 0 calc(25% - 10px);
  text-align: left;
  margin-bottom: 20px;
}

.city-item svg {
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .city-item {
    flex: 0 0 calc(33.33% - 10px);
  }
  .cities-modal {
    margin-top: 17%;
    top: 50%;
    left: 50%;
  }
}

/* Empty slots for the last row on desktop */
.city-list:after {
  content: "";
  flex: 0 0 calc(25% - 10px);
}

/* Empty slots for the last row on mobile */
@media (max-width: 768px) {
  .city-list:after {
    content: "";
    flex: 0 0 calc(33.33% - 10px);
  }
}